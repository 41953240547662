import React, {forwardRef, useEffect, useState, useMemo, useRef} from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { actions } from "../../actions";
import { MoreButton, HotKeyProxy } from "../ui-kit";
import { AlertContext, MessageContext, Messages} from "@cargo/ui-kit";
import { getCRDTItem, applyChangesToYType } from "../../lib/multi-user/redux";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { setHomePage, pinContent, overlayContent, deleteContent, duplicateContent } from "../../lib/page-list-manager";
import { displayContent } from "../../lib/page-list-manager";
import { CRDTState } from "../../globals";
import { set } from 'lib0/encoding';


const PageListItemContextUI = (props) => {

	const [clickedToClose, setClickedToClose] = useState(false);
	const clickedToCloseRef = useRef(clickedToClose);
	
	useEffect(() => {
        return () => {
			props.onClose && props.onClose(clickedToCloseRef.current);
        };
    }, []);

	useEffect(() => {
		clickedToCloseRef.current = clickedToClose;
	}, [clickedToClose]);

	const doOpenPinSettings = (e) => {
		props.openPinSettings ? props.openPinSettings(e) : openPinSettingsLocal(e);
	}
	
	const openPinSettingsLocal = (e) => {

		props.addUIWindow({
			group: 'modal',
			component: import('../right-menu-bar/pin-settings-window'),
			id: 'pin-settings-window',
			props: {
				type: 'popover', 
				positionType: 'from-invokeeWindow',
				buttonPos: props?.controllerProps?.button,
				buttonOffset: props.buttonOffset,
				borderRadius: 'radius-all', 
				autoHeight: true,
				pageID: props.page.id,
				windowName: 'pin-settings',
				invokeTarget: e.currentTarget,
				closeOnSingleClickout: true,
				ignoreSessionPosition: true,
				additionalMarginBottom: 7
			}
		},{
			removeGroup: true
		})
	}

	const doOpenOverlaySettings = (e) => {
		props.openOverlaySettings ? props.openOverlaySettings(e) : openOverlaySettingsLocal(e);
	}

	const openOverlaySettingsLocal = (e) => {
		props.addUIWindow({
			group: 'modal',
			component: import('../right-menu-bar/overlay-settings-window'),
			id: 'overlay-settings-window',
			props: {
				type: 'popover', 
				positionType: 'from-invokeeWindow',
				buttonPos: props?.controllerProps?.button,
				buttonOffset: props.buttonOffset,
				borderRadius: 'radius-all', 
				autoHeight: true,
				pageID: props.page.id,
				windowName: 'overlay-settings',
				invokeTarget: e.currentTarget,
				closeOnSingleClickout: true,
				ignoreSessionPosition: true,
				additionalMarginBottom: 7
			}
		},{
			removeGroup: true
		})
	}


	const openPageDesign = () => {

		// navigate the admin to this page
		props.history.push('/'+props.page.id)

		// open the 'local styles' for this page
		props.addUIWindow({
			group: 'right-menu-bar',
			component: import('../right-menu-bar/page-settings-window-controller'),
			id: 'page-settings-window-controller',
			props: {
				type: 'popover', 
				positionType: 'fixed-right', 
				borderRadius: 'radius-all', 
				tabbed: true,
				windowName: 'page-settings-window-controller',
				openingTab: 'page'
			}
		},{
			removeAll: true
		})
		
	}

	const isHomepage = props.page.id === props.site.homepage_id;
	const isMobileHomepage = props.page.id === props.site.mobile_homepage_id;
	const isDesktopAndMobileHomepage = isHomepage && isMobileHomepage;
	const hasMobileHomepage = props.site.mobile_homepage_id !== null;

	return (
		<>
			<ContextMenuButton 
				label="Duplicate" 
				onPointerUp = { e => { 
					duplicateContent(props.page.id).then(duplicateContent => {
						props.history.push('/'+duplicateContent.id)
					})
				}}
				// immediatePointerUp={()=>{ setClickedToClose(true) }}
			/>	

			<AlertContext.Consumer>
			    {(Alert) => (
			    	<>{props.page.pin ? 

			    		<ContextMenuButton 
			    			label={<>Pin...</>}
			    			onPointerUp = { e => {
		    					doOpenPinSettings(e);
			    			}}
							immediatePointerUp={()=>{ setClickedToClose(true) }}
			    		/>

		    		: <ContextMenuButton
						label={`Pin...`}
						name="pin"
						immediatePointerUp={()=>{ 
							setClickedToClose(true) 
						}}
						onPointerUp = { e => { 
							
							// if there are pin settings already stored, then pin the page
							if ( props.page?.pin_options && !_.isEmpty(props.page?.pin_options) 
							// but not if this page is set as "home"
								&& !isHomepage
							) {
								if( props.page.password_enabled && !props.page.display ){

									Alert.openModal({
										header: 'Pinning this page will remove password-protection and make it visible.',
										type: 'confirm',
										HotKeyProxy: HotKeyProxy,
										onConfirm: (options) => {

											let pageId = props.page.id;

											const { CRDTItem: draftPage } = getCRDTItem({
												reducer: 'pages.byId',
												item: pageId
											});

											applyChangesToYType(draftPage, {
												password_enabled: false,
											});

											displayContent(pageId, true);

											pinContent(props.page.id, !props.page.pin);

											// open the pin settings menu without pinning
											doOpenPinSettings(e);
										},
										onDeny: (options) => {
											props.onClose && props.onClose(false);
										},
										onClose: () => {
											props.onClose && props.onClose(false);
										}
									});

									return

								} else if(  props.page.password_enabled ){
									// Ask to remove password before page is pinned...
									Alert.openModal({
										header: 'This will remove password-protection for this Page',
										type: 'confirm',
										HotKeyProxy: HotKeyProxy,
										onConfirm: (options) => {

											let pageId = props.page.id;
											const { CRDTItem: draftPage } = getCRDTItem({
												reducer: 'pages.byId',
												item: pageId
											});

											applyChangesToYType(draftPage, {
												password_enabled: false
											});

											pinContent(props.page.id, !props.page.pin);

											// open the pin settings menu without pinning
											doOpenPinSettings(e);
										},
										onDeny: (options) => {
											props.onClose && props.onClose(false);
										},
										onClose: () => {
											props.onClose && props.onClose(false);
										}
									});

									return

								} else if( !props.page.display ){ 

										let pageId = props.page.id;
										displayContent(pageId, true)
										pinContent(props.page.id, !props.page.pin);
										// open the pin settings menu without pinning
										doOpenPinSettings(e);

								} else {

									// Pin page...
									if (!props.page.pin) {
										pinContent(props.page.id, true);
									}
									// open the pin settings menu without pinning
									doOpenPinSettings(e);

								}

							} else {

								if (!props.page.pin) {
									pinContent(props.page.id, true);
								}
								if( !props.page.display ){
									displayContent(props.page.id, true);
								}
								// open the pin settings menu without pinning
								doOpenPinSettings(e);
							}

						}}
						/>
					}</>
			    )}
			</AlertContext.Consumer>


			<AlertContext.Consumer>
			    {(Alert) => (<>
					{props.page.overlay ? 

						<ContextMenuButton 
							label={<>Overlay...</>}
							immediatePointerUp={()=>{ setClickedToClose(true) }}
							onPointerUp = { e => {
								doOpenOverlaySettings(e);
							}}
						/>

					: <ContextMenuButton
						label={`Overlay...`}
						name="overlay"
						immediatePointerUp = {()=>{ setClickedToClose(true) }}
						onPointerUp = { e => { 
							// if there are pin settings already stored, then pin the page
							if ( props.page?.pin_options && !_.isEmpty(props.page?.pin_options) 
							// but not if this page is set as "home"
								&& !isHomepage
							) {
								if( props.page.password_enabled && !props.page.display ){

									Alert.openModal({
										header: 'Setting this page to Overlay will remove password-protection and make it visible.',
										type: 'confirm',
										HotKeyProxy: HotKeyProxy,
										onConfirm: (options) => {

											let pageId = props.page.id;

											const { CRDTItem: draftPage } = getCRDTItem({
												reducer: 'pages.byId',
												item: pageId
											});

											applyChangesToYType(draftPage, {
												password_enabled: false,
											});

											displayContent(pageId, true);

											overlayContent(props.page.id, !props.page.overlay);

											// open the pin settings menu without pinning
											doOpenOverlaySettings(e);
										},
										onDeny: (options) => {
											props.onClose && props.onClose(false);
										},
										onClose: () => {
											props.onClose && props.onClose(false);
										}
									});

									return

								} else if(  props.page.password_enabled ){
									// Ask to remove password before page is pinned...
									Alert.openModal({
										header: 'This will remove password-protection for this Page',
										type: 'confirm',
										HotKeyProxy: HotKeyProxy,
										onConfirm: (options) => {

											let pageId = props.page.id;
											const { CRDTItem: draftPage } = getCRDTItem({
												reducer: 'pages.byId',
												item: pageId
											});

											applyChangesToYType(draftPage, {
												password_enabled: false
											});

											overlayContent(props.page.id, !props.page.overlay);

											// open the pin settings menu without pinning
											doOpenOverlaySettings(e);
										},
										onDeny: (options) => {
											props.onClose && props.onClose(false);
										},
										onClose: () => {	
											props.onClose && props.onClose(false);
										}
									});

									return

								} else if( !props.page.display ){ 

										let pageId = props.page.id;
										displayContent(pageId, true)
										overlayContent(props.page.id, !props.page.overlay);
										// open the pin settings menu without pinning
										doOpenOverlaySettings(e);

								} else {
									// overlay page...
									if (!props.page.overlay) {
										overlayContent(props.page.id, true);
									}
									// open the pin settings menu without pinning
									doOpenOverlaySettings(e);

								}

							} else {

								if (!props.page.overlay) {

									overlayContent(props.page.id, true);
								}
								if( !props.page.display ){
									displayContent(props.page.id, true);
								}
								// open the pin settings menu without pinning
								doOpenOverlaySettings(e);
							}
						}}
					/>}
			    </>)}
			</AlertContext.Consumer>

			<ContextMenuButton 
				label={<>Settings...</>}
				immediatePointerUp = {()=>{ setClickedToClose(true) }}
				onPointerUp = { e => {
					if (typeof props.openPageSettings === 'function') {
						props.openPageSettings(e);
					}
					
				}}
			/>

			<ContextSubMenu
				label="Set as Homepage"
				layer={1}
			>
				<ContextMenuCheckbox
					label={`Desktop & Mobile`}
					name="is_homepage"
					value={isDesktopAndMobileHomepage}
					// immediatePointerUp = {()=>{ setClickedToClose(true) }}
					onPointerUp = { e => {
						setHomePage(props.page.id, !isDesktopAndMobileHomepage, 'mobile');
						setHomePage(props.page.id, !isDesktopAndMobileHomepage, 'desktop');

						displayContent(props.page.id, true);
						
					}}
				/>
				<ContextMenuCheckbox
					label={`Desktop` }
					name="is_homepage"
					value={isHomepage && !isMobileHomepage}
					// immediatePointerUp = {()=>{ setClickedToClose(true) }}
					onPointerUp = { e => {
						if (isDesktopAndMobileHomepage || isMobileHomepage) {
							setHomePage(props.page.id, false, 'mobile');
						}

						setHomePage(props.page.id, isDesktopAndMobileHomepage ? true : !isHomepage, 'desktop');

						displayContent(props.page.id, true);
					}}
				/>
				<ContextMenuCheckbox
					label="Mobile"
					name="is_homepage"
					value={isMobileHomepage && !isHomepage}
					// immediatePointerUp = {()=>{ setClickedToClose(true) }}
					onPointerUp = { e => {
						if (isDesktopAndMobileHomepage || isHomepage) {
							setHomePage(props.page.id, false, 'desktop');
						}

						setHomePage(props.page.id, isDesktopAndMobileHomepage ? true : !isMobileHomepage, 'mobile');

						displayContent(props.page.id, true);
					} }
				/>
				
			</ContextSubMenu>

			<hr/>

			<AlertContext.Consumer>
			    {(Alert) => (
			    	<ContextMenuButton 
			    		label="Delete" 
						immediatePointerUp = {()=>{ setClickedToClose(true) }}
			    		onPointerUp = { e => {
		            		Alert.openModal({
		            		    header: 'Delete page “'+props.page.title+'”?',
		            		    // message: 'Deleted pages cannot be recovered', 
		            		    type: 'confirm',
		            		    denyButton: 'Cancel',
		            		    confirmButton: 'OK',
		            		    HotKeyProxy: HotKeyProxy,
		            		    onConfirm: (options) => {
		            		    	deleteContent('page', props.page.id)
		            		    },
		            		    onDeny: (options) => {
									props.onClose && props.onClose(false);
								},
		            		    onClose: () => {
									props.onClose && props.onClose(false);
								}
	            		   });
			    			
			    		}}
			    	/>
			    )}
			</AlertContext.Consumer>

			<MessageContext.Consumer>
				{(Message) => (
					<ContextMenuButton 
						disabled={props.page.crdt_state === CRDTState.New}
						label="Copy" 
						// immediatePointerUp = {()=>{ setClickedToClose(true) }}
						onPointerUp = { e => {
							navigator.clipboard.writeText(`cargo:${props.site.id}:${props.page.id}`);
	
							setTimeout(() => {
								Message.showMessage({
									messageText: Messages.SITE_COPIED,
									preventClickout: false,
									duration: 2000,
									className: 'tall'
								});
							})
							
						}}
					/>
				)}
			</MessageContext.Consumer>

			<hr/>

			<ContextMenuButton 
				label={<>Direct Link <em>↗</em></>}
				// immediatePointerUp = {()=>{ setClickedToClose(true) }}
				onPointerUp = { e => {
					let pageLink = props.site.direct_link+"/"+props.page.purl;
					window.open(pageLink, "_blank")
				}}
			/>

		</>
	);

};

function mapReduxStateToProps(state, ownProps) {
	return { 
		pageListWindow: state.uiWindows.byId['page-list-window'],
		userID: state.user?.id,
	};
}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps
)( PageListItemContextUI ));
